import { type ApplicationConfig, enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { DYNAMIC_ENV, DYNAMIC_ENVIRONMENT_SCHEMA } from '@libs/core/environment';

import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments';

fetch('/environment/environment.json')
  .then((response) => {
    if (!response.ok) {
      throw new Error('Failed to load environment configuration');
    }

    return response.json();
  })
  .then((dynamicEnvironment) => {
    const result = DYNAMIC_ENVIRONMENT_SCHEMA.safeParse(dynamicEnvironment);
    if (!result.success) {
      // Log the detailed validation errors and prevent bootstrapping
      console.error('Configuration validation failed:', result.error.errors);
      throw new Error('Invalid configuration format. Stopping application bootstrap.');
    }

    if (environment.production) {
      enableProdMode();
    }

    const appConfigWithEnvironment: ApplicationConfig = {
      ...appConfig,
      providers: [
        ...appConfig.providers,
        {
          provide: DYNAMIC_ENV,
          useValue: result.data,
        },
      ],
    };
    bootstrapApplication(AppComponent, appConfigWithEnvironment).catch((err) => console.error(err));
  });
