import {
  type ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideServiceWorker } from '@angular/service-worker';

import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { provideToastr } from 'ngx-toastr';

import {
  OCP_TRANSLATE_LOADER_FACTORY,
  OcpMissingTranslationHandler,
} from '@ocp/fusion-cdk/translate';

import { ROUTE_MANAGER_TOKEN } from '@libs/core/tokens';
import { STATIC_ENV } from '@libs/core/environment';
import { authInterceptor } from '@libs/modules/auth';
import { apiInterceptor, zodValidationInterceptor } from '@libs/core/services';
import { MAT_TOOLTIP_CUSTOM_CONFIG } from '@libs/core/constants';

import { RouteManagerService } from '@core/services';

import { environment } from '../environments';
import { routes } from './app.routes';
import { provideStoreConfig } from './provide-store-config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideToastr({ positionClass: 'toast-bottom-right' }),
    provideStoreConfig(),
    provideHttpClient(
      withInterceptors([zodValidationInterceptor, authInterceptor, apiInterceptor]),
    ),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: OCP_TRANSLATE_LOADER_FACTORY,
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: OcpMissingTranslationHandler,
        },
      }),
    ),
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: MAT_TOOLTIP_CUSTOM_CONFIG },
    { provide: STATIC_ENV, useValue: environment },
    { provide: ROUTE_MANAGER_TOKEN, useClass: RouteManagerService },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
