import { type Routes } from '@angular/router';

import { authGuard } from './pages/auth';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/workspace').then((m) => m.WORKSPACE_ROUTES),
    canActivate: [authGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth').then((m) => m.AUTH_ROUTES),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
