import { type EnvironmentProviders, isDevMode, makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools, type StoreDevtoolsOptions } from '@ngrx/store-devtools';

import { provideFeatures } from '@ocp/utils-advanced/ngrx';

import { FilterManagerEffects } from '@libs/modules-ui/filter-manager';
import { TaskEffects, taskFeature } from '@libs/modules/task';
import { AuthEffects, authFeature } from '@libs/modules/auth';
import { GlobalEffects, globalFeature } from '@libs/core/store';
import { embeddedPlatformFeature } from '@libs/modules/platform';
import {
  DataModelClassEffects,
  dataModelClassFeature,
  DataModelEffects,
  dataModelFeature,
  DataModelProjectEffects,
  dataModelProjectFeature,
  DataModelVersionEffects,
  dataModelVersionFeature,
} from '@libs/modules/data-model-workshop';
import { DocumentJobEffects, documentJobFeature } from '@libs/modules/document-job';
import {
  DocumentEffects,
  documentFeature,
  DocumentTreeEffects,
  documentTreeFeature,
} from '@libs/modules/document';
import { PowerBiEffects, powerBiFeature } from '@libs/modules/power-bi';
import {
  OcpProjectEffects,
  ocpProjectFeature,
  ProjectEffects,
  projectFeature,
  projectListFeature,
} from '@libs/modules/project';
import { ProjectSettingsEffects, projectSettingsFeature } from '@libs/modules/project-settings';
import { TagEffects, tagFeature, TagTreeEffects, tagTreeFeature } from '@libs/modules/tag';
import { TaskJobEffects, taskJobFeature } from '@libs/modules/task-job';
import { AttributeEffects, attributeFeature } from '@libs/modules/attribute';
import { TaskManagerEffects } from '@libs/modules-ui/task-manager';

import { AppEffects, appFeature } from '@core/store';

const features = [
  globalFeature,
  appFeature,
  authFeature,
  projectFeature,
  projectListFeature,
  tagFeature,
  projectSettingsFeature,
  embeddedPlatformFeature,
  documentFeature,
  powerBiFeature,
  documentJobFeature,
  taskJobFeature,
  attributeFeature,
  ocpProjectFeature,
  dataModelProjectFeature,
  dataModelFeature,
  dataModelVersionFeature,
  dataModelClassFeature,
  tagTreeFeature,
  documentTreeFeature,
  taskFeature,
];

const effects = [
  GlobalEffects,
  AppEffects,
  AuthEffects,
  ProjectEffects,
  ProjectSettingsEffects,
  TagEffects,
  DocumentEffects,
  PowerBiEffects,
  DocumentJobEffects,
  TaskJobEffects,
  TaskManagerEffects,
  AttributeEffects,
  OcpProjectEffects,
  DataModelProjectEffects,
  DataModelEffects,
  DataModelVersionEffects,
  DataModelClassEffects,
  TagTreeEffects,
  DocumentTreeEffects,
  TaskEffects,
  FilterManagerEffects,
];

const storeDevtoolsOptions: StoreDevtoolsOptions = {
  name: 'Platform Dashboard',
  maxAge: 25,
  logOnly: !isDevMode(),
  autoPause: true,
  trace: true,
  traceLimit: 25,
};

export function provideStoreConfig(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideStore(),
    provideFeatures(features),
    provideEffects(effects),
    provideStoreDevtools(storeDevtoolsOptions),
  ]);
}
